import ImportanceFilter from '../Filters/ImportanceFilter';
import CountryFilter from '../Filters/CountryFilter';
import style from '../../styles/filters/FilterComponents.module.css';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useEffect } from 'react';

const FilterComponents: React.FC = () => {
  const { activeTab } = useEconCalendar();
  const toShowImportanceFilterSection = ['events', 'earnings-reports'].includes(activeTab);
  const { isWebsite } = useEconCalendarParams();
  const isMobile = useScreenDimensions();

  useEffect(() => {
    const element = document.getElementById('ESMA-rw');
    const filterElement = document.getElementById('filterComponent');
    if (element || filterElement) {
      const height = element?.offsetHeight || 0;

      const calcHeight = `${window.innerHeight - 56 - height}px`;

      if (filterElement) filterElement.style.height = calcHeight;
    }
  }, []);

  return (
    <div
      id="filterComponent"
      className={
        isWebsite && window?.location?.host?.includes('ig.com')
          ? style.websiteFilters
          : isWebsite && isMobile
            ? style.mobileWebsiteFilters
            : style.filters
      }
    >
      {toShowImportanceFilterSection && <ImportanceFilter />}
      <CountryFilter />
    </div>
  );
};

export default FilterComponents;
