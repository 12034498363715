import { countryContinentMapping } from './accountRegions';
import { countryCodeToNameMap } from './countryCodeNameMapping';
import { convertDateToUserTimezone, getLocalTime } from './econ-utils';

interface CountryContinentMapping {
  [key: string]: string[];
}

export const isDST = (timeZoneOffset) => {
  const now = new Date();
  const january = new Date(now.getFullYear(), 0, 1);

  // Helper function to get the offset in hours
  function getOffset(date) {
    return -date.getTimezoneOffset() / 60;
  }

  // Get the current offset and January offset
  const currentOffset = getOffset(now);
  const januaryOffset = getOffset(january);

  // DST is in effect if the current offset is different from the January offset
  return timeZoneOffset !== januaryOffset;
};

export const convertDateToString = (
  dateString: string,
  offset: number,
  mobile: boolean,
  customLocale: string = 'en',
  t,
) => {
  if (dateString) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    };
    // get the event date relative to the timezone offset
    const finalFormattedDate = new Intl.DateTimeFormat(customLocale, options).format(
      new Date(dateString),
    );
    const finalFormattedDateWithoutLocale = new Intl.DateTimeFormat('en-US', options).format(
      new Date(dateString),
    );
    const updatedOffset = offset;
    let date2;
    if (offset) {
      // getting account's time
      const accountTime = getAccountTime(getLondonTime(), updatedOffset);

      const [day, month, yearTime] = accountTime.split('/');
      const [year, time] = yearTime.split(', ');
      date2 = new Date(`${month}/${day}/${year}`);
    } else {
      const localTime = getLocalTime();
      const onlyDatePart = localTime.split(',')[0];
      const [day, month, year] = onlyDatePart.split('/');
      date2 = new Date(`${month}/${day}/${year}`);
    }

    // Calculate the difference in milliseconds
    const diffInMs = new Date(finalFormattedDateWithoutLocale).getTime() - date2.getTime();

    // Convert milliseconds to days
    const msInDay = 24 * 60 * 60 * 1000;
    const dayDifference = Math.round(diffInMs / msInDay);

    return dayDifference === -1
      ? mobile
        ? finalFormattedDate
        : `${finalFormattedDate} (${t('yesterday')})`
      : dayDifference === 0
        ? mobile
          ? finalFormattedDate
          : `${finalFormattedDate} (${t('today')})`
        : dayDifference === 1
          ? mobile
            ? finalFormattedDate
            : `${finalFormattedDate} (${t('tomorrow')})`
          : finalFormattedDate;
  }
};

export const formatToTwoDecimalPlaces = (value, decimalPlaces = 2) => {
  const valueMapping = {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  };
  try {
    // Extract the preceding text, numeric part (including sign), and the suffix using a regular expression
    const match = value.toString().match(/^([^0-9\-]*-?\d+(\.\d+)?)(.*)/);

    if (!match) {
      return value;
    }

    let numberPart = match[1];
    let suffix = match[3];
    const suffixKey = Object.keys(valueMapping).find((key) => suffix.toLowerCase().includes(key));
    if (suffixKey) {
      suffix = suffix.replace(suffixKey.trim(), valueMapping[suffixKey.trim()]);
    }
    // Find the decimal point in the number part
    const decimalIndex = numberPart.indexOf('.');

    if (decimalIndex !== -1) {
      // If there is a decimal point, truncate to two decimal places without rounding
      numberPart = numberPart.substring(0, decimalIndex + decimalPlaces + 1);
    }
    // Return the truncated number with the suffix
    return `${numberPart}${suffix}`;
  } catch (e) {
    return value;
  }
};

export const convertValueOnLocale = (value, locale, decimalPlaces = 2) => {
  try {
    // Regular expression to extract the numeric part, prefix (currency symbol), and suffix
    const match = value.toString().match(/^([^0-9.-]*)([0-9.-]+)(.*)$/);

    if (!match) {
      return value;
    }

    const currencyPrefix = match[1] || ''; // Extract the prefix (currency symbol, if present)
    const numericValue = parseFloat(match[2]); // Extract the numeric part
    const suffix = match[3] || ''; // Extract the suffix (e.g., million, %, B)
    let formattedNumber;
    if (currencyPrefix) {
      const valueWithSuffix = numericValue + suffix;
      formattedNumber = getValueWithCurrency(valueWithSuffix, locale, currencyPrefix, true);
      // Check if the suffix is present in the formatted number; if not, append it
      if (!formattedNumber?.includes(suffix.trim())) {
        formattedNumber += suffix;
      }

      return formattedNumber;
    } else {
      if (isNaN(numericValue)) {
        return value; // Return the original value if it's not a valid number
      }

      // Format the numeric part with up to two decimal places, without rounding
      formattedNumber = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalPlaces,
      }).format(numericValue);

      // Return the formatted number with the original currency prefix and suffix
      return `${currencyPrefix}${formattedNumber}${suffix}`;
    }
  } catch (e) {
    return value;
  }
};

export const formatPaymentDate = (dateString: string, customLocale: string = 'en-US') => {
  if (dateString) {
    // Create a Date object from the string
    const date = new Date(dateString);

    // Get the day of the week (full name)
    const day = new Intl.DateTimeFormat(customLocale, { weekday: 'long' }).format(date);

    // Get the date components (day, month, year)
    const dayOfMonth = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed (January is 0)
    const year = date.getFullYear();

    // Format the date in the desired format
    return `${day}, ${dayOfMonth}/${month.toString().padStart(2, '0')}/${year}`;
  }
};

export const formatPaymentDateDividendsMobile = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if necessary
  const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month name
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getFormattedTime = (time, offset) => {
  if (offset !== null) {
    // Given UTC date and time
    const utcDate = new Date(time);

    // Convert UTC date to local date with the specified offset
    const localDate = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);

    // Format the local date to the desired time string
    const hours = String(localDate.getUTCHours()).padStart(2, '0');
    const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } else if (time) {
    time = new Date(time);
    return new Intl.DateTimeFormat('en-GB', {
      timeStyle: 'short',
    }).format(time);
  }
};

export const importanceMapping = {
  0: 'low',
  1: 'low',
  2: 'medium',
  3: 'high',
};

export const getCountryName = (data, key) => {
  return data?.[key] === 'European Union' ? 'Euro Area' : data?.[key];
};

// returns the country code of the country with this name
export const searchCountryCode = (name: string): string => {
  // @ts-ignore
  return Object.keys(countryCodeToNameMap).find((key) => countryCodeToNameMap[key] === name);
};

let today = new Date();
let timeOffset = today.getTimezoneOffset() * 60;

export const convertUTCDateToNumber = (dateString: string) => {
  return new Date(dateString).valueOf() / 1000 - timeOffset;
};

export const getLondonTime = () => {
  const now = new Date();
  const options = {
    timeZone: 'Europe/London',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const filteredOptions = Object.fromEntries(
    Object.entries(options)?.filter(([_, v]) => v !== undefined),
  );
  const formatter = new Intl.DateTimeFormat('en-GB', filteredOptions);

  return formatter.format(now);
};

export const getTimeDiff = (accountTime, eventDate, timezoneOffset) => {
  try {
    const [dateNormal, eventDateUTC] = convertDateToUserTimezone(eventDate, timezoneOffset);
    // Parse accountTime and eventTime into Date objects
    const { day, month, year, hours, minutes, seconds } = parseDateString(accountTime);
    const accountTimeDate = new Date(year, month - 1, day, hours, minutes, seconds);
    const eventTimeDate = new Date(dateNormal);

    // Ensure both dates are valid
    if (isNaN(accountTimeDate.getTime()) || isNaN(eventTimeDate.getTime())) {
      return '';
    }

    // Calculate the difference in milliseconds
    const timeDiff = eventTimeDate.getTime() - accountTimeDate.getTime();

    // Convert difference to a readable format (e.g., hours, minutes, seconds)
    const diffInSeconds = Math.floor(timeDiff / 1000);
    let diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours =
      diffInMinutes > 0 ? Math.floor(diffInMinutes / 60) : Math.ceil(diffInMinutes / 60);

    if (diffInHours === 0 && diffInMinutes < 60) {
      if (diffInMinutes > 0) {
        if (diffInMinutes === 1) return `in ${diffInMinutes} min`;
        return `in ${diffInMinutes} mins`;
      }
    } else if (diffInHours > 0 && diffInHours < 24) {
      diffInMinutes -= diffInHours * 60;
      if (diffInHours === 1 && diffInMinutes === 0) return `in ${diffInHours} hour`;
      else {
        return diffInMinutes < 10
          ? `in ${diffInHours}:0${diffInMinutes} hours`
          : `in ${diffInHours}:${diffInMinutes} hours`;
      }
    } else return '';
  } catch (e) {
    return '';
  }
};

function isNewDST() {
  const now = new Date();

  // Get the current timezone offset (in minutes)
  const currentOffset = now.getTimezoneOffset();

  // Get the timezone offset for January 1st (winter)
  const winterOffset = new Date(now.getFullYear(), 0, 1).getTimezoneOffset();

  // Get the timezone offset for July 1st (summer)
  const summerOffset = new Date(now.getFullYear(), 6, 1).getTimezoneOffset();

  // If the current offset is different from the winter offset, DST is in effect
  return currentOffset !== winterOffset;
}

const isDSTActive = isNewDST();

export const getAccountTime = (londonTimeString: string, offset: number) => {
  // Parse the London time string back into a Date object
  const [datePart, timePart] = londonTimeString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');
  const londonDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second),
    ),
  );

  // Calculate the new time with the given timezone offset
  const utcTime = londonDate.getTime();
  const newTime = new Date(utcTime + offset * 3600000);

  // Format the new time
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC', // Format as UTC since we already adjusted the offset
  };
  const filteredOptions = Object.fromEntries(
    Object.entries(options)?.filter(([_, v]) => v !== undefined),
  );
  const formatter = new Intl.DateTimeFormat('en-GB', filteredOptions);
  const formattedTime = formatter.format(newTime);
  return formattedTime;
};

const adjustDate = (utcDateString, daysOffset, timezoneOffset, endOfDay = false) => {
  // Parse the given date string (in UTC format)
  const [datePart, timePart] = utcDateString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');

  // Create a Date object representing the given date and time in UTC
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  // Adjust the date by the given number of days
  date.setUTCDate(date.getUTCDate() + daysOffset);

  if (endOfDay) {
    // Set time to 11:59:59 PM in the adjusted timezone
    date.setUTCHours(23, 59, 59, 999);
  } else {
    // Set time to 12:00 AM in the adjusted timezone
    date.setUTCHours(0, 0, 0, 0);
  }
  // Return the adjusted date in ISO format
  return date.toISOString();
};

export const getCalendarEventRelativeDate = (dateString, timezoneOffset) => {
  // Parse the given date string in YYYY-MM-DD format
  const dateValue = new Date(dateString);

  // Extract the year, month, and day
  const year = dateValue.getFullYear();
  const month = dateValue.getMonth(); // Months are zero-indexed
  const day = dateValue.getDate();
  // Create a Date object representing the given date at 00:00:00 UTC
  const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

  // Extract the integer and fractional parts of the timezoneOffset
  const offsetHours = Math.trunc(timezoneOffset);
  const offsetMinutes = (timezoneOffset - offsetHours) * 60;

  // Calculate the start date adjusted for the timezone offset
  const startDate = new Date(date);
  startDate.setUTCHours(startDate.getUTCHours() - offsetHours);
  startDate.setUTCMinutes(startDate.getUTCMinutes() - offsetMinutes);

  // Calculate the end date adjusted for the timezone offset
  const endDate = new Date(date);
  endDate.setUTCHours(23 - offsetHours);
  endDate.setUTCMinutes(59 - offsetMinutes);
  endDate.setUTCSeconds(59);
  endDate.setUTCMilliseconds(999);
  // Return the adjusted dates in ISO format
  return [startDate.toISOString(), endDate.toISOString()];
};

export const getDateRangeLocal = (activeTab, dateSelected = '') => {
  let fromDate = new Date();
  let toDate = new Date();

  if (dateSelected == '') {
    // Subtract 1 day from the current date for the start date
    fromDate.setDate(fromDate.getDate() - 1);

    // Add 13 days to the current date for the end date
    toDate.setDate(toDate.getDate() + 13);

    // Create local start time at 00:00:00
    const localStart = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate(),
      0,
      0,
      0,
    );

    // Create local end time at 23:59:59
    const localEnd = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
      isDSTActive ? 22 : 23,
      59,
      59,
    );

    const utcStartString = localStart.toISOString();
    const utcEndString = localEnd.toISOString();

    return [utcStartString, utcEndString];
  } else {
    if (activeTab === 'events') {
      // 1. Parse the input date string
      const date = new Date(dateSelected);

      // 2. Create local date and time for 00:00:00 (ignoring time zone for UTC)
      const localStart = new Date(date.getFullYear(), date.getMonth(), date.getDate());

      // 3. Create local date and time for 23:59:59
      const localEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

      // 5. Format the dates (optional)
      const utcStartString = localStart.toISOString();
      const utcEndString = localEnd.toISOString();
      return [utcStartString, utcEndString];
    } else {
      const year = new Date(dateSelected).getFullYear();
      const month = String(new Date(dateSelected).getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(new Date(dateSelected).getDate()).padStart(2, '0');
      const updatedDate = `${year}-${month}-${day}`;
      return [updatedDate, updatedDate];
    }
  }
};

export const getDateRangeAccountTime = (
  activeTab,
  timezoneOffset: number | null = null,
  dateSelected = '',
) => {
  if (dateSelected === '') {
    const londonTime = getLondonTime();
    const updatedTimezoneOffset = isDST(timezoneOffset) ? timezoneOffset - 1 : timezoneOffset;
    const accountTime = getAccountTime(londonTime, updatedTimezoneOffset);
    const dateMinusOneDay = adjustDate(accountTime, -1, updatedTimezoneOffset);
    const datePlusThirteenDays = adjustDate(accountTime, 13, updatedTimezoneOffset, true);
    return [dateMinusOneDay, datePlusThirteenDays];
  } else {
    if (activeTab === 'events') {
      return getCalendarEventRelativeDate(dateSelected, timezoneOffset);
    } else {
      const year = new Date(dateSelected).getFullYear();
      const month = String(new Date(dateSelected).getMonth()).padStart(2, '0'); // Months are zero-indexed
      const day = String(new Date(dateSelected).getDate()).padStart(2, '0');
      const updatedDate = `${year}-${month}-${day}`;
      return [updatedDate, updatedDate];
    }
  }
};

export const getCountryCode = (countryName) => {
  const countryCode = Object.entries(countryCodeToNameMap)?.filter(([code, name]) => {
    if (name === countryName) {
      return code;
    }
  });
  return countryCode;
};

export const getContinentForCountry = (countryCode: string): string | null => {
  for (const [continent, countries] of Object.entries(countryContinentMapping)) {
    if (countries?.includes(countryCode)) {
      return continent;
    }
  }
  return null; // Return undefined if the country code is not found
};

export const getValueWithCurrency = (
  data: string | number,
  locale: string,
  currency: string,
  dataCurrencySymbol: boolean,
) => {
  const parseValue = (value: string | number): { numberValue: number; suffix?: string } => {
    if (typeof value === 'string') {
      value = value?.replace(/,/g, '');
      const match = value.match(/([\d\.,]+)\s?([KMGBTQ])?/);

      if (match) {
        let [, number, suffix] = match;
        number = number?.replace(',', '.'); // Ensure correct decimal separator
        let numericValue = parseFloat(number);
        return { numberValue: numericValue, suffix: suffix };
      }
    }
    return { numberValue: Number(value) };
  };

  const formatNumber = (number: number, locale: string): string => {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const formatCurrency = (
    number: number,
    locale: string,
    currency: string,
    suffix: string = '',
  ): string => {
    let parts;

    if (dataCurrencySymbol) {
      parts = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).formatToParts(number);
    } else {
      parts = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).formatToParts(number);
    }

    const currencySymbolPart = parts.find((part) => part.type === 'currency');
    const currencySymbol = dataCurrencySymbol
      ? currency
      : currencySymbolPart
        ? currencySymbolPart.value
        : '';

    // Check if currency symbol should be before or after the number
    const hasCurrencyBeforeNumber = parts[0].type === 'currency';
    if (hasCurrencyBeforeNumber) {
      if (data[0] === '-') return `${currencySymbol}-${formatNumber(number, locale)}${suffix}`;
      else return `${currencySymbol}${formatNumber(number, locale)}${suffix}`;
    } else {
      if (data[0] === '-') return `-${formatNumber(number, locale)}${suffix} ${currencySymbol}`;
      else return `${formatNumber(number, locale)}${suffix} ${currencySymbol}`;
    }
  };

  try {
    const { numberValue, suffix } = parseValue(data);
    return formatCurrency(numberValue, locale, currency, suffix);
  } catch (e) {
    return data;
  }
};

export const decodeHtmlEntities = (str) => {
  // Create a temporary DOM element
  let temp = document.createElement('textarea');
  // Set the innerHTML of the temporary element to the string containing HTML entities
  temp.innerHTML = str;
  // Return the text content of the temporary element is the decoded string
  return temp.value;
};

export const arraysHaveSameElements = (arr1: string[], arr2: string[]) => {
  return new Set(arr1).size === new Set(arr2).size && arr1.every((item) => arr2?.includes(item));
};

export const getStartPrice = (singleDataEntry, tabKey, locale) => {
  try {
    const currency = singleDataEntry['currency'];
    const [value0, value1] = singleDataEntry[tabKey].split('-');
    let startBase = null;
    let startUpperEnd = null;
    if (value0) {
      startBase = getValueWithCurrency(value0, locale, currency, false);
    }

    if (value1) {
      startUpperEnd = getValueWithCurrency(value1, locale, currency, false);
    }

    if (startBase && startUpperEnd) return `${startBase} - ${startUpperEnd}`;
    else if (startBase) return startBase;
    else if (startUpperEnd) return startUpperEnd;
    else return '';
  } catch (e) {
    return singleDataEntry[tabKey];
  }
};

export const continents: Record<string, string[]> = {
  Americas: ['US', 'CA', 'MX', 'BR', 'CO'],
  'Euro Area': ['EU', 'DE', 'FR', 'ES', 'IT', 'GR', 'AT', 'IE', 'FI', 'NL', 'PT', 'BE'],
  'Non-Eurozone Europe': ['CZ', 'DK', 'GB', 'SE', 'CH', 'PL', 'RU', 'NO', 'HU'],
  'Asia and Pacific': ['JP', 'AU', 'NZ', 'CN', 'IN', 'KR', 'TH', 'SG', 'HK', 'TW', 'ID', 'PH'],
  'Middle East and Africa': ['IL', 'ZA', 'AE'],
};

export const findContinentbyCountry = (value: string): string | null => {
  const obj: CountryContinentMapping = countryContinentMapping;
  for (const [key, values] of Object.entries(obj)) {
    if (values?.includes(value)) {
      return key;
    }
  }
  return null;
};

export const holidayDifferentLocales = [
  'Holidays',
  'Feiertage',
  'Vacaciones',
  'Vacances',
  'Vacanze',
  '休日',
  'Ferier',
  'Feriados',
  'Semester',
  '假期',
];

export const eventNameMappingForMobile = {
  events: 'Macroeconomic',
  'earnings-reports': 'Earnings',
  'dividends-reports': 'Dividends',
  ipos: 'IPOs',
  'stock-splits': 'Splits',
};

export const formatDateForAddToCalendar = (inputDate, timezoneOffset) => {
  // Parse the input date
  const date = new Date(inputDate);

  // Apply the timezone offset
  const offsetDate = new Date(date.getTime() + timezoneOffset * 60 * 60 * 1000);

  // Extract day, month, year, hours, minutes, and seconds
  const day = String(offsetDate.getUTCDate()).padStart(2, '0');
  const month = String(offsetDate.getUTCMonth()).padStart(2, '0'); // Months are 0-based
  const year = offsetDate.getUTCFullYear();
  const hours = String(offsetDate.getUTCHours()).padStart(2, '0');
  const minutes = String(offsetDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(offsetDate.getUTCSeconds()).padStart(2, '0');

  // Format the date and time
  const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};

export const parseDateString = (dateString) => {
  const [datePart, timePart] = dateString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');
  return {
    day: parseInt(day, 10),
    month: parseInt(month, 10),
    year: parseInt(year, 10),
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
    seconds: parseInt(seconds, 10),
  };
};

export const addToCalendarButtonCheck = (dateStr1, dateStr2) => {
  const date1 = parseDateString(dateStr1);
  const date2 = parseDateString(dateStr2);

  if (date1.year !== date2.year) {
    return date1.year > date2.year;
  }
  if (date1.month !== date2.month) {
    return date1.month > date2.month;
  }
  if (date1.day !== date2.day) {
    return date1.day > date2.day;
  }
  if (date1.hours !== date2.hours) {
    return date1.hours > date2.hours;
  }
  if (date1.minutes !== date2.minutes) {
    return date1.minutes > date2.minutes;
  }
  return date1.seconds > date2.seconds;
};

export const shouldShowAddToCalendar = (date: string, timezoneOffset: number | null = null) => {
  if (timezoneOffset == null) {
    if (new Date(date) > new Date()) return true;
    else return false;
  } else {
    const londonTime = getLondonTime();
    const updatedTimezoneOffset = timezoneOffset;
    const accountTime = getAccountTime(londonTime, updatedTimezoneOffset);
    const eventTime = formatDateForAddToCalendar(date, updatedTimezoneOffset);
    // check the account time and event time
    if (addToCalendarButtonCheck(eventTime, accountTime)) return true;
    else return false;
  }
};

export const icsToJSON = (icsData) => {
  // First, unfold lines (i.e., concatenate lines that start with a space or tab to the previous line)
  const unfoldedData = icsData?.replace(/\r?\n[ \t]/g, '');

  const lines = unfoldedData.split(/\r?\n/);
  const jsonData = {};
  let currentComponent = null;

  lines.forEach((line) => {
    // Handle component start (e.g., VEVENT)
    if (line.startsWith('BEGIN:')) {
      const componentName = line?.replace('BEGIN:', '').trim();
      jsonData[componentName] = {}; // Create a new object for this component
      currentComponent = componentName;
    }
    // Handle component end (e.g., END:VEVENT)
    else if (line.startsWith('END:')) {
      currentComponent = null;
    }
    // Handle key-value pairs, including fields with parameters like ATTENDEE
    else if (currentComponent && line?.includes(':')) {
      const [key, value] = line.split(/:(.+)/); // Split only at the first colon

      // Special handling for fields like ATTENDEE that may contain parameters
      if (key?.includes(';')) {
        const [field, ...params] = key.split(';');
        const paramObj = params?.reduce((acc, param) => {
          const [k, v] = param.split('=');
          acc[k.trim()] = v ? v.trim() : '';
          return acc;
        }, {});
        jsonData[currentComponent][field.trim()] = {
          value: value ? value.trim() : '',
          params: paramObj,
        };
      } else {
        jsonData[currentComponent][key.trim()] = value ? value.trim() : '';
      }
    }
  });

  return jsonData;
};
