import { useEffect, useMemo, useRef, useState } from 'react';
import { useEconCalendar } from '../hooks/useEconCalendar';
import TableRows from './TableRowsData/TableRows';
import style from '../styles/EconCalendarTableRows.module.css';
import { useTranslation } from 'react-i18next';
import {
  eventsTabKeys,
  eventsTabKeysMobile,
  dividendsTabKeys,
  ipoTabKeys,
  stockTabKeys,
  earningsTabKeys,
  checkName,
  headingsNameCheck,
  dataAccessProperty,
  earningsTabKeysMobile,
  dividendsTabKeysMobile,
  ipoTabKeysMobile,
  stockTabKeysMobile,
} from '../utils/tabsDataAccess-utils';
import useScreenDimensions from '../hooks/useScreenDimension';
import { useSessionData } from '../hooks/use-session-data';
import { getTimeDiff } from '../utils/util';
import { getLocalTime } from '../utils/econ-utils';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';

export default function EconCalendarTableRows({ data, date }) {
  const { activeTab, calendarWidth } = useEconCalendar();
  const { isWebsite } = useEconCalendarParams();
  const isMobile = useScreenDimensions();
  const { timezoneOffset } = useSessionData();
  const [currentTime, setCurrentTime] = useState(new Date());
  const { t } = useTranslation();
  const mainSectionRef = useRef();

  const tabKeys = useMemo(() => {
    if (activeTab === 'events') {
      return isMobile ? eventsTabKeysMobile : eventsTabKeys;
    } else if (activeTab === 'dividends-reports') {
      return isMobile ? dividendsTabKeysMobile : dividendsTabKeys;
    } else if (activeTab === 'ipos') {
      return isMobile ? ipoTabKeysMobile : ipoTabKeys;
    } else if (activeTab === 'stock-splits') {
      return isMobile ? stockTabKeysMobile : stockTabKeys;
    } else if (activeTab === 'earnings-reports') {
      return isMobile ? earningsTabKeysMobile : earningsTabKeys;
    }
  }, [activeTab, isMobile]);

  useEffect(() => {
    let intervalId;
    if (activeTab === 'events') {
      intervalId = setInterval(() => {
        setCurrentTime(new Date());
      }, 60000);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [activeTab]);

  const widthStyling = ['events', 'earnings-reports'].includes(activeTab)
    ? calendarWidth < 821
      ? 'LessThan821'
      : calendarWidth < 900
        ? 'LessThan900'
        : ''
    : '';

  const scrollToEvent = (eventDate, eventIndex) => {
    if (mainSectionRef.current && data && data[eventDate] && data[eventDate][eventIndex]) {
      const eventElement = mainSectionRef.current.querySelector(
        `#${data[eventDate][eventIndex]['id']}`,
      );
      if (eventElement) {
        eventElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  useEffect(() => {
    if (data && Object.entries(data).length && activeTab === 'events' && !isWebsite) {
      let shouldBreak = false;

      for (const [date, obj] of Object.entries(data)) {
        let count = 0;
        for (const [innerIndex, value1] of Object.entries(obj)) {
          count += 1;

          const diff = getTimeDiff(getLocalTime(), value1.date, null);
          if (diff !== '' && diff) {
            scrollToEvent(date, count - 1);
            shouldBreak = true;
            break; // Exit the inner loop
          }
        }

        if (shouldBreak) {
          break; // Exit the outer loop
        }
      }
    }
  }, [activeTab]);

  return (
    <div className={isMobile ? style.dataWrapperMobile : style.dataWrapper}>
      {!isMobile && (
        <div className={activeTab} data-testid={activeTab}>
          {tabKeys &&
            tabKeys.length > 0 &&
            tabKeys.map((item, index) => {
              return (
                <span
                  className={`${
                    (item === 'country' || item === 'importance') &&
                    ['events', 'earnings-reports'].includes(activeTab)
                      ? item + widthStyling
                      : headingsNameCheck[item] || item
                  } font`}
                  key={item}
                >
                  {item === 'expand' || item === 'alert'
                    ? ''
                    : item === 'time' && activeTab === 'events' && timezoneOffset
                      ? `GMT${timezoneOffset > 0 ? '+' + timezoneOffset : timezoneOffset}`
                      : t(checkName[item] || item)}
                </span>
              );
            })}
        </div>
      )}
      <div className={style.tableRowWrapper} ref={mainSectionRef}>
        {data &&
          Object.entries(data).map((key, value) => {
            const keyDate = key[0];
            const keyData = key[1];

            return (
              keyData &&
              keyData.length > 0 && (
                <div key={value}>
                  <div className={style.tableRowData}>
                    {keyDate.substring(0, 10) === date &&
                      keyData &&
                      keyData.length > 0 &&
                      keyData.map((singleDataEntry, ind) => {
                        const marketTrend = [
                          singleDataEntry?.actualValue - singleDataEntry.previousValue,
                          singleDataEntry?.revenueValue - singleDataEntry.revenuePreviousValue,
                        ];
                        return (
                          <TableRows
                            key={ind}
                            entryIndex={ind}
                            tabKeys={tabKeys}
                            headingsNameCheck={headingsNameCheck}
                            singleDataEntry={singleDataEntry}
                            marketTrend={marketTrend}
                            dataAccessProperty={dataAccessProperty}
                            widthStyling={widthStyling}
                          />
                        );
                      })}
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}
