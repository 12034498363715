import { useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import HistoricalChart from '../HistoricalChart';
import TabRowDataIndividiualRow from './TabRowDataIndividiualRow';
import { downloadIcsFile } from '../../api/EconCalendarApi';
import style from '../../styles/TableRows.module.css';
import '../../styles/TableRows.scss';
import '../../styles/TableRowsMobile.scss';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { holidayDifferentLocales, shouldShowAddToCalendar } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useSessionData } from '../../hooks/use-session-data';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import { getFormattedDescription } from '../../utils/tabsDataAccess-utils';

export default function TableRows({
  singleDataEntry,
  tabKeys,
  entryIndex,
  headingsNameCheck,
  marketTrend,
  dataAccessProperty,
  widthStyling,
}) {
  const buttonRef = useRef(null);
  const firstDivRef = useRef(null);
  const addToCalendarRef = useRef(null);

  const {
    activeTab,
    chartData,
    selectedChartSection,
    isExpanded,
    isChartLoading,
    fetchHistoricalData,
    toggleDetail,
    updatedData,
    authString,
    showSearch,
    updateShowSearch,
  } = useEconCalendar();

  const { baseColorSkeleton, highlightColorSkeleton, locale, isIos, isWebsite, isAndroid } =
    useEconCalendarParams();

  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const {
    // cst,
    calendarApiUrl,
    timezoneOffset,
  } = useSessionData();

  const handleExpand = (selectedData) => {
    fetchHistoricalData(selectedData);
  };

  const notWebsite = isIos || isAndroid;
  const isHoliday = holidayDifferentLocales.includes(singleDataEntry['category']);

  const handleClick = (event) => {
    const isEnterKey = event.key === 'Enter';
    const isDivClicked = firstDivRef.current.contains(event.target);

    if (isEnterKey || isDivClicked) {
      if (activeTab === 'events') {
        if (!isMobile) {
          !isHoliday && handleExpand(singleDataEntry);
        } else {
          if (!isHoliday) {
            if (isWebsite) {
              handleExpand(singleDataEntry);
            } else {
              toggleDetail(singleDataEntry);
            }
            showSearch && updateShowSearch();
          }
        }
      }
      buttonRef.current.blur(); // Remove focus when clicked
    }
  };

  const handleKeyDown = (event) => {
    if (addToCalendarRef.current && addToCalendarRef.current.contains(document.activeElement)) {
      return; // Do nothing if the focus is on the "Add to calendar" button
    }

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (like form submission)
      handleClick(event); // Call handleClick when Enter is pressed
    }
  };

  const checkUpdateEligibility =
    singleDataEntry.id in updatedData &&
    new Date(updatedData[singleDataEntry.id].lastUpdate) > new Date(singleDataEntry.lastUpdate);

  if (checkUpdateEligibility) {
    if (updatedData[singleDataEntry.id].event === '')
      updatedData[singleDataEntry.id].event = singleDataEntry.event;
    if (!updatedData[singleDataEntry.id].description)
      updatedData[singleDataEntry.id].description = singleDataEntry.description;
    if (!updatedData[singleDataEntry.id].category) {
      updatedData[singleDataEntry.id].category = singleDataEntry.category;
    }
    singleDataEntry = checkUpdateEligibility ? updatedData[singleDataEntry.id] : singleDataEntry;
  }

  const handleAddToCalendarKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent default behavior
      downloadIcsFile(
        selectedChartSection,
        locale,
        // cst,
        authString,
        calendarApiUrl,
      );
    }
  };

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={`${isMobile ? style.tableRowsMobile : style.tableRows} ${
        isMobile && isIos ? style.iosTableRowsMobile : style.none
      } ${isMobile && isHoliday ? style.holidayRow : style.none}`}
      aria-expanded={selectedChartSection?.id === singleDataEntry?.id}
      aria-label={singleDataEntry.event || singleDataEntry.name}
      id={singleDataEntry.id}
    >
      <div
        ref={firstDivRef}
        className={`${isMobile ? activeTab + 'RowMobile' : activeTab + 'Row'} ${
          checkUpdateEligibility && 'updatedValues'
        } ${isHoliday && 'holiday'}`}
        data-testid={`${isMobile ? activeTab + 'RowMobile' : activeTab + 'Row'}`}
      >
        {tabKeys &&
          tabKeys.length > 0 &&
          tabKeys.map((tabKey, index) => {
            if (Array.isArray(tabKey)) {
              return (
                <div className={'div' + index} key={index}>
                  {tabKey.map((tabValue, indexValue) => {
                    if (
                      holidayDifferentLocales?.includes(singleDataEntry.category) &&
                      !(tabValue?.includes('country') || tabValue?.includes('event')) &&
                      activeTab === 'events'
                    ) {
                      return;
                    }

                    if (Array.isArray(tabValue)) {
                      return (
                        <div className={'column' + indexValue} key={indexValue + index}>
                          {tabValue.map((value, indexValue2) => {
                            if (Array.isArray(value)) {
                              return (
                                <div
                                  className={'section' + indexValue2}
                                  key={value.toString() + indexValue2 + index}
                                >
                                  {value.map((property, valueIndex) => {
                                    return (
                                      <TabRowDataIndividiualRow
                                        key={`${entryIndex}${valueIndex}${index}`}
                                        activeTab={activeTab}
                                        tabKey={property}
                                        index={valueIndex}
                                        headingsNameCheck={headingsNameCheck}
                                        singleDataEntry={singleDataEntry}
                                        marketTrend={marketTrend}
                                        dataAccessProperty={dataAccessProperty}
                                        widthStyling={widthStyling}
                                      />
                                    );
                                  })}
                                </div>
                              );
                            } else {
                              return (
                                <TabRowDataIndividiualRow
                                  key={`${indexValue2}${value.toString()}${index}`}
                                  activeTab={activeTab}
                                  tabKey={value}
                                  index={indexValue2}
                                  headingsNameCheck={headingsNameCheck}
                                  singleDataEntry={singleDataEntry}
                                  marketTrend={marketTrend}
                                  dataAccessProperty={dataAccessProperty}
                                  widthStyling={widthStyling}
                                />
                              );
                            }
                          })}
                        </div>
                      );
                    } else {
                      return (
                        <div className={tabValue} key={`${indexValue}_${tabValue}_${index}`}>
                          <TabRowDataIndividiualRow
                            key={`${entryIndex}${tabKey}${index}`}
                            activeTab={activeTab}
                            tabKey={tabKey}
                            index={indexValue}
                            headingsNameCheck={headingsNameCheck}
                            singleDataEntry={singleDataEntry}
                            marketTrend={marketTrend}
                            dataAccessProperty={dataAccessProperty}
                            widthStyling={widthStyling}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              );
            } else {
              return (
                <TabRowDataIndividiualRow
                  key={`${entryIndex}${tabKey}${index}`}
                  activeTab={activeTab}
                  tabKey={tabKey}
                  index={index}
                  headingsNameCheck={headingsNameCheck}
                  singleDataEntry={singleDataEntry}
                  marketTrend={marketTrend}
                  dataAccessProperty={dataAccessProperty}
                  widthStyling={widthStyling}
                />
              );
            }
          })}
      </div>

      {!notWebsite &&
        isExpanded &&
        activeTab === 'events' &&
        selectedChartSection.id === singleDataEntry.id && (
          <div className={style.expandSection}>
            <div
              className={`${isMobile && isWebsite && isExpanded ? style.onlyWebsiteDesc : style.onlyDescription} ${
                chartData && chartData.length > 0 && style.chartAndDescription
              } ${
                !(singleDataEntry.description && chartData && chartData.length > 0) &&
                style.noInfoWrapper
              }`}
            >
              {isChartLoading ? (
                <div className={style.skeletons}>
                  <div>
                    <SkeletonTheme
                      baseColor={baseColorSkeleton}
                      highlightColor={highlightColorSkeleton}
                    >
                      <Skeleton height={207} />
                    </SkeletonTheme>
                  </div>
                  <div>
                    <SkeletonTheme
                      baseColor={baseColorSkeleton}
                      highlightColor={highlightColorSkeleton}
                    >
                      <Skeleton height={207} />
                    </SkeletonTheme>
                  </div>
                </div>
              ) : (
                <>
                  {chartData && chartData.length > 0 && (
                    <div className={style.chartWrapper}>
                      <HistoricalChart
                        data={chartData}
                        width={isMobile && isWebsite ? window.innerWidth - 20 : 478}
                        height={207}
                      />
                    </div>
                  )}
                  {chartData.length > 0 && singleDataEntry.description && (
                    <div className={style.separator}> </div>
                  )}
                  {singleDataEntry.description && (
                    <div className={`${style.description}`}>
                      <h4> {t('Summary')} </h4>
                      <p
                        className={
                          chartData.length || singleDataEntry.description.length > 600
                            ? !(isWebsite && isMobile) && style.biggerPara
                            : style.smallerPara
                        }
                      >
                        {getFormattedDescription(selectedChartSection, locale)}
                      </p>
                    </div>
                  )}
                  {!isChartLoading && !chartData.length && !singleDataEntry.description && (
                    <div className={style.noInformation}> {t('No information available')} </div>
                  )}
                </>
              )}
            </div>
            {shouldShowAddToCalendar(singleDataEntry.date, timezoneOffset) && (
              <div className={`${style.addToCalendar}`}>
                <button
                  onClick={async () => {
                    const resp = await downloadIcsFile(
                      selectedChartSection,
                      locale,
                      // cst,
                      authString,
                      calendarApiUrl,
                    );
                    const { updatedBlob, filename } = resp;
                    const link = document.createElement('a');
                    // check for browser compatibility, older browsers (chrome/safari) might support window.navigator.webkitURL.createObjectURL()
                    link.href = window.URL.createObjectURL(updatedBlob); // Use window.URL for broader compatibility
                    link.download = filename;
                    link.click();

                    // Revoke object URL immediately to avoid memory leaks
                    window.URL.revokeObjectURL(link.href);
                  }}
                  data-testid="add-to-calendar"
                  onKeyDown={handleAddToCalendarKeyDown}
                  ref={addToCalendarRef}
                >
                  {t('Add to calendar')}
                </button>
              </div>
            )}
          </div>
        )}
    </button>
  );
}
