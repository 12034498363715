import { useMemo } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/ZoneCountryImportance.module.css';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import ExpandFiltersIcon from '../IconComponents/ExpandFiltersIcon';
import CountryFilter from './CountryFilter';
import ImportanceFilter from './ImportanceFilter';
import { supportedCountryCodes } from '../../utils/supportedFlagIconList';
import { continents, getContinentForCountry, getCountryCode } from '../../utils/util';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import EventCollapse from '../../assets/svgs/EventCollapse';
import ArrowExpandIcon from '../../assets/svgs/ArrowExpandIcon';
import { useSessionData } from '../../hooks/use-session-data';
import { accounts } from '../../utils/accountRegions';

const ZoneCountryImportance = () => {
  const {
    extendedImportanceFilter,
    updateExtendedImportanceFilter,
    updateExtendedCountryFilter,
    activeTab,
    extendedCountryFilter,
    isDataLoading,
    allCountry,
    selectedImportance,
  } = useEconCalendar();
  const toShowImportanceFilterSection = ['events', 'earnings-reports'].includes(activeTab);
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { isIos, isWebsite } = useEconCalendarParams();
  const countriesFiltered = allCountry?.length;
  const { igCompanySiteId } = useSessionData();

  const countryList = useMemo(() => {
    let count = 0;
    const value = accounts[igCompanySiteId] && getCountryCode(accounts[igCompanySiteId]);
    const continentName = getContinentForCountry(igCompanySiteId);

    if (!continents[continentName]?.includes(value[0][0])) {
      continents[continentName]?.push(value[0][0]);
    }

    const flagsToBeShown = [];
    Object.keys(continents).forEach((continent) => {
      const countries = continents[continent];
      if (count >= countriesFiltered || flagsToBeShown.length > 3 || count > 3) return; // Use >= instead of > to ensure correct count
      countries.forEach((country) => {
        if (allCountry && allCountry?.length > 0 && allCountry?.includes(country)) {
          flagsToBeShown.push(country);
          count++;
        }
      });
    });
    return flagsToBeShown;
  }, [allCountry]);

  const totalCountry = Object.values(continents)?.reduce(
    (totalCountry, country) => (totalCountry += country.length),
    0,
  );

  return (
    <div
      className={`${style.zoneCountryImportance} ${
        isMobile && isIos
          ? style.zoneCountryImportanceIos
          : isMobile && isWebsite
            ? style.websiteResponsiveFilterBtn
            : style.none
      }`}
    >
      {toShowImportanceFilterSection && (
        <div className={style.filtersWrapper}>
          <button
            className={`${style.alignment} ${
              isDataLoading ? style.noCursor : style.cursor
            } importanceFilter`}
            onClick={updateExtendedImportanceFilter}
            data-testid="importance_filter_expand"
            disabled={isDataLoading}
          >
            <div
              className={`${style.importance} ${style.filterHeading} ${
                isMobile && isIos ? style.iosFilterSection : style.none
              }`}
            >
              <div> {t('Importance')} </div>
              {isIos && isMobile && (
                <div className={style.iosAllText}>
                  <span>
                    {selectedImportance.length === 3
                      ? t('All')
                      : selectedImportance.length + ' / 3'}
                  </span>
                  {extendedImportanceFilter ? <ArrowExpandIcon /> : <EventCollapse />}
                </div>
              )}
              {(!isMobile || isWebsite) && (
                <div
                  className={`${isMobile && !isWebsite ? style.mobileFlags : style.importanceLevels}`}
                >
                  <span
                    className={
                      !isMobile && !selectedImportance?.includes('high')
                        ? style.notSelected
                        : style.highImportance
                    }
                  >
                    <p> {t('H')} </p>
                  </span>
                  <span
                    className={
                      !isMobile && !selectedImportance?.includes('medium')
                        ? style.notSelected
                        : style.mediumImportance
                    }
                  >
                    <p> {t('M')} </p>
                  </span>
                  <span
                    className={
                      !isMobile && !selectedImportance?.includes('low')
                        ? style.notSelected
                        : style.lowImportance
                    }
                  >
                    <p> {t('L')} </p>
                  </span>
                </div>
              )}
            </div>
            {(!isMobile || isWebsite) && <ExpandFiltersIcon />}
          </button>
          <div
            className={`${!isMobile ? style.importanceFilter : style.none} ${
              extendedImportanceFilter && !isMobile
                ? style.showFilterImportance
                : !isWebsite
                  ? style.showFilterImportanceIos
                  : style.none
            }`}
          >
            {extendedImportanceFilter && (!isMobile || (isMobile && isIos) || isWebsite) && (
              <ImportanceFilter />
            )}
          </div>
        </div>
      )}
      {toShowImportanceFilterSection && isMobile && isIos && (
        <div className={style.separator}></div>
      )}
      <div className={style.filtersWrapper}>
        <button
          className={`${style.alignment} ${
            isDataLoading ? style.noCursor : style.cursor
          } countryFilter`}
          onClick={updateExtendedCountryFilter}
          aria-label={t('Toggle Country Filter')} // ARIA label for accessibility
          aria-expanded={extendedCountryFilter ? 'true' : 'false'} // Indicates if the filter is expanded or not
          data-testid="country_filter_expand"
          disabled={isDataLoading}
        >
          <div
            className={`${style.country} ${style.filterHeading} ${
              isMobile && isIos ? style.iosFilterSection : style.none
            }`}
          >
            <div> {t('Country/Region')} </div>
            {isIos && isMobile && (
              <div className={style.iosAllText}>
                <span>
                  {allCountry.length === totalCountry
                    ? t('All')
                    : allCountry.length + ' / ' + totalCountry}
                </span>
                {extendedCountryFilter ? <ArrowExpandIcon /> : <EventCollapse />}
              </div>
            )}
            {(!isMobile || isWebsite) && (
              <div className={`${isMobile && !isWebsite ? style.mobileFlags : style.flags}`}>
                {countryList.slice(0, Math.min(3, countryList.length)).map((countryCode, index) => (
                  <div key={index}>
                    <FlagIcon
                      code={
                        supportedCountryCodes?.includes(countryCode as FlagIconCode)
                          ? (countryCode as FlagIconCode)
                          : null
                      }
                    />
                  </div>
                ))}
                <div className={style.flagCount}>
                  {`${
                    countriesFiltered && countriesFiltered > 3 ? '+' + (countriesFiltered - 3) : ''
                  }`}
                </div>
              </div>
            )}
          </div>
          {(!isMobile || isWebsite) && <ExpandFiltersIcon />}
        </button>
        <div
          className={`${
            !isMobile
              ? toShowImportanceFilterSection
                ? isWebsite
                  ? style.countryFilterImportanceWebsite
                  : style.countryFilterImportance
                : isWebsite
                  ? style.countryFilterNoImportanceWebsite
                  : style.countryFilterNoImportance
              : style.noFilter
          } ${
            extendedCountryFilter && !isMobile
              ? toShowImportanceFilterSection
                ? isWebsite
                  ? style.countryFilterMarginWebsite
                  : style.countryFilterMargin
                : isWebsite
                  ? style.countryFilterNoMarginWebsite
                  : style.countryFilterNoMargin
              : style.noFilter
          }`}
        >
          {extendedCountryFilter && (!isMobile || (isMobile && isIos)) && <CountryFilter />}
          {isMobile && isIos && extendedCountryFilter && (
            <div className={style.applyBtnIosWrapper}>
              <button className={style.applyBtnIos} onClick={updateExtendedCountryFilter}>
                {t('Ok')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZoneCountryImportance;
