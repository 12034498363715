import style from '../styles/EconCalendarFilters.module.css';
import ZoneCountryImportance from './Filters/ZoneCountryImportance';
import SearchBox from './Filters/SearchBox';
import { useEconCalendar } from '../hooks/useEconCalendar';
import FilterExpandIcon from '../assets/svgs/MobileFilterExpandIconNoFilters';
import FilterExpandIconFiltersSelected from '../assets/svgs/FilterExpandIconFiltersSelected';
import useScreenDimensions from '../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import SearchExpandIcon from '../assets/svgs/SearchExpandIcon';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';

export default function EconCalendarFilters() {
  const {
    updateShowSearch,
    updateShowFilters,
    showFilters,
    showSearch,
    allCountry,
    selectedImportance,
  } = useEconCalendar();
  const { t } = useTranslation();
  const isMobile = useScreenDimensions();
  const { isIos, isAndroid, isWebsite } = useEconCalendarParams();
  const showSearchClass = showSearch ? style.filterWrapperMobileSearch : style.filterWrapperMobile;
  const notWebsite = isIos || isAndroid;
  return (
    <div className={`${notWebsite ? showSearchClass : style.filterWrapper}`}>
      {notWebsite ? (
        <div className={style.mobileFilterView}>
          {!showFilters && !showSearch && (
            <button onClick={updateShowFilters} data-testid="mobile-expand-filters">
              {allCountry?.length > 0 || selectedImportance?.length > 0 ? (
                <FilterExpandIconFiltersSelected />
              ) : (
                <FilterExpandIcon />
              )}
            </button>
          )}
          {!showFilters && !showSearch && (
            <button onClick={updateShowSearch} data-testid="mobile-expand-search">
              <SearchExpandIcon />
            </button>
          )}
        </div>
      ) : isMobile && isWebsite ? (
        <ZoneCountryImportance />
      ) : (
        <div className={style.filter}>
          <ZoneCountryImportance />
          <SearchBox />
        </div>
      )}
    </div>
  );
}
